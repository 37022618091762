class ModalContents {
  constructor() {
    this.injectHTML();

    // Get the modal
    this.modal1 = document.querySelector('.modal-1');
    this.modal2 = document.querySelector('.modal-2');
    this.modal3 = document.querySelector('.modal-3');
    this.modal4 = document.querySelector('.modal-4');

    // Get the button that opens the modal
    this.btn1 = document.getElementById('myBtn-1');
    this.btn2 = document.getElementById('myBtn-2');
    this.btn3 = document.getElementById('myBtn-3');
    this.btn4 = document.getElementById('myBtn-4');

    // Get the <span> element that closes the modal
    //this.span = document.getElementsByClassName("close")[0]
    this.span = document.querySelectorAll('.close');

    // reference document body to stop scroll on modal open
    this.docbody = document.getElementsByTagName('BODY')[0];

    this.events();
  }

  events() {
    // listen for open click
    this.btn1.addEventListener('click', e => this.openTheModal(e));
    this.btn2.addEventListener('click', e => this.openTheModal2(e));
    this.btn3.addEventListener('click', e => this.openTheModal3(e));
    this.btn4.addEventListener('click', e => this.openTheModal4(e));

    // listen for close click
    // this.span.addEventListener("click", () => this.closeTheModal() )
    this.span.forEach(el =>
      el.addEventListener('click', e => this.closeTheModal())
    );

    // pushes any key
    document.addEventListener('keyup', e => this.keyPressHandler(e));

    // test code for click outside the modal
    window.addEventListener('click', e => {
      //test for a specific modal
      //if (e.target == this.modal1) { this.closeTheModal()}

      // test for all in a class
      // something outside the modal box was clicked as it detects that the background div was clicked.
      if (e.target.classList.contains('modal-generic')) {
        this.closeTheModal(); //alert("close the modal")
      }
    });
  }

  keyPressHandler(e) {
    if (e.keyCode == 27) {
      this.closeTheModal();
    }
  }

  openTheModal(e) {
    e.preventDefault();
    this.modal1.classList.add('modal-1--is-visible');
    this.docbody.classList.add('body-no-scroll');
    // e.stopPropogation()
  }

  openTheModal2(e) {
    e.preventDefault();
    this.modal2.classList.add('modal-2--is-visible');
    this.docbody.classList.add('body-no-scroll');
  }
  openTheModal3(e) {
    e.preventDefault();
    this.modal3.classList.add('modal-3--is-visible');
    this.docbody.classList.add('body-no-scroll');
  }
  openTheModal4(e) {
    e.preventDefault();
    this.modal4.classList.add('modal-4--is-visible');
    this.docbody.classList.add('body-no-scroll');
  }

  closeTheModal() {
    this.modal1.classList.remove('modal-1--is-visible');
    this.modal2.classList.remove('modal-2--is-visible');
    this.modal3.classList.remove('modal-3--is-visible');
    this.modal4.classList.remove('modal-4--is-visible');
    this.docbody.classList.remove('body-no-scroll');
  }

  injectHTML() {
    document.body.insertAdjacentHTML(
      'beforeend',
      `
        <!-- The Modals -->

        <div class="modal-1 modal-generic">
            <!-- Modal content -->
            <div class="modal-content">
            <div class="modal-header-2">
                <span class="close">&times;</span>
                <h2>Brand Identity</h2>
            </div>

            <div class="modal-body">
               
                <h5>From boutique clothing, organic gardening, Catering  and Financial Sector Branding. We can provide flexible logos and brand identity, with a simple elegant branding solution.</h5>
                <h5>
                We develop branding that’s aligned to your goals, that’s full of life and is tailored specifically to the needs of your business, product and customers.
                Defining your brand, essential to engaging customers with your business and building long term success.
                </h5>
            </div>

            <div class="modal-body modal-body--grid-1">
                <img src="assets/images/portfolio/visible-portfolio-010h.jpg" class="top1 modal-body--image" alt="">   
                <img src="assets/images/portfolio/visible-portfolio-012h.jpg" class="top2 modal-body--image" alt="">   
                <p class="mid1">&nbsp;</p>
                <img src="assets/images/portfolio/visible-portfolio-011m.jpg" class="mid2 modal-body--image" alt="">  
                <img src="assets/images/portfolio/visible-portfolio-011f.jpg" class="bot1 modal-body--image" alt="">  
                <img src="assets/images/portfolio/visible-portfolio-012f.jpg" class="bot2 modal-body--image" alt="">  
            </div>

            <div class="modal-body modal-body--grid-2">
            <img src="assets/images/portfolio/visible-portfolio-014h.jpg" class="top1" alt="">   
            <img src="assets/images/portfolio/visible-portfolio-015h.jpg" class="top2" alt="">   
            <p class="mid1">&nbsp;</p>
            <img src="assets/images/portfolio/visible-portfolio-013h.jpg" class="mid2 modal-body--image" alt="">  
           
            <img src="assets/images/portfolio/visible-portfolio-007h.jpg" class="bot1 modal-body--image" alt="">  
            <img src="assets/images/portfolio/visible-portfolio-008h.jpg" class="bot2 modal-body--image" alt="">  
             </div>

             <div class="modal-body">
            
          
   
             <div class="container ">
             <div class="halves-box">
                 <img src="assets/images/portfolio/visible-portfolio-025h.jpg"  alt="" />
             </div>
             <div class="halves-box">
               <img src="assets/images/portfolio/visible-portfolio-026h.jpg"  alt="" />
             </div>
             </div>
   
             </div>
    
             <div class="modal-footer">
                <!-- <h3>Modal Footer</h3> -->
                <h3>&nbsp;</h3>
             </div>
            </div>
        </div>


        <!-- ---------------------------------------- -->
        <div class="modal-2 modal-generic"> 
        <!-- Modal content -->
          <div class="modal-content">
          <div class="modal-header-2">
              <span class="close">&times;</span>
              <h2>Web Design</h2>
          </div>

            <div class="modal-body">
              <h5>If you are an existing  business or just starting out with a great idea and need a simple web site, our overall approach to a project is the same. You will get a quality service with quality website.</h5>
              <h5> We have a wealth of experience in delivering quality websites for all purposes.                       
              </h5>
            </div>

            <div class="modal-body">
                <div class="container ">
                  <!-- <div class="halves-box"> -->
                      <img src="assets/images/portfolio/rjm-6.jpg"  alt="" />
                  <!-- </div> -->
                </div>

                <div class="container ">
                <div class="halves-box">
                  <img src="assets/images/portfolio/gob2.jpg"  alt="Green Over Blue" />
                </div>
                <div class="halves-box">
                  <img src="assets/images/portfolio/visible-portfolio-014h.jpg"  alt="" />
                </div>
                </div>


                <div class="container ">
                  <!-- <div class="halves-box"> -->
                      <img src="assets/images/portfolio/sc.jpg"  alt="Studio Connections" />
                  <!-- </div> -->
                </div>

                <div class="container ">
                  <div class="thirds-box">
                    <img src="assets/images/portfolio/rjm-9.jpg"  alt="" />
                  </div>
                  <div class="thirds-box">
                    <img src="assets/images/portfolio/rjm-10.jpg"  alt="" />
                  </div>
                  <div class="thirds-box">
                    <img src="assets/images/portfolio/wh2.jpg"  alt="" />
                  </div>
                </div>
                <div class="container ">
                  <!-- <div class="halves-box"> -->
                      <img src="assets/images/portfolio/jen1.jpg"  alt="" />
                  <!-- </div> -->
                </div>

                <div class="container ">
                <div class="halves-box">
                    <img src="assets/images/r1.jpg"  alt="" />
                </div>
                <div class="halves-box">
                    <img src="assets/images/portfolio/flourists.jpg"  alt="" />
                </div>
                </div>

                <div class="container ">
                  <div class="thirds-box">
                    <img src="assets/images/portfolio/jen2.jpg"  alt="" />
                  </div>
                  <div class="thirds-box">
                    <img src="assets/images/portfolio/visible-portfolio-028h.jpg"  alt="" />
                  </div>
                  <div class="thirds-box">
                    <img src="assets/images/portfolio/visible-portfolio-029h.jpg"  alt="" />
                  </div>
                </div>

                <div class="container ">
                <div class="halves-box">
                <img src="assets/images/portfolio/wh3.jpg"  alt="" />
               
                </div>
                <div class="halves-box">
                    <img src="assets/images/portfolio/barone-1a.jpg"  alt="" />
                </div>
                </div>

            </div>
            <div class="modal-footer">
                <h3>&nbsp;</h3>
            </div>
        </div>
    </div> 
        <!-- ---------------------------------------- -->
        <!-- ---------------------------------------- -->

    
        <div class="modal-3 modal-generic">
        <>
        <!-- Modal content -->
        <div class="modal-content">
        <div class="modal-header-2">
            <span class="close">&times;</span>
            <h2>E-Commerce</h2>
        </div>
      
        <div class="modal-body">
            <h5>From small simple shops to large complex e-commerce stores...</h5>
            <h5> We have a solution for you.</h5>
        </div>
      
      
        <div class="modal-body modal-body--grid-3">
            <img src="assets/images/portfolio/didiesse.jpg" class="top1 modal-body--image" alt="">     
            <p class="mid1">&nbsp;</p>
            <img src="assets/images/portfolio/barone-2a.jpg" class="mid2 modal-body--image" alt="">  
            <img src="assets/images/portfolio/barone-1.jpg" class="bot1 modal-body--image" alt="">  
            <img src="assets/images/portfolio/barone-3b.jpg" class="bot2 modal-body--image" alt="">  
        </div>
      
      
        <div class="modal-body modal-body--grid-2">
        <img src="assets/images/portfolio/rjm-1.jpg" class="top1 modal-body--image" alt="">   
        <img src="assets/images/portfolio/rjm-2.jpg" class="top2 modal-body--image" alt="">   
        <p class="mid1">&nbsp;</p>
        <img src="assets/images/portfolio/rjm-3a.jpg" class="mid2 modal-body--image" alt="">  
       
        <img src="assets/images/portfolio/rjm-4.jpg" class="bot1 modal-body--image" alt="">  
        <img src="assets/images/portfolio/rjm-5.jpg" class="bot2 modal-body--image" alt="">  
         </div>
      
 

      
            <div class="modal-footer">
                <!-- <h3>Modal Footer</h3> -->
                <h3>&nbsp;</h3>
            </div>
        </div>
      
      </div>


        <!-- ---------------------------------------- -->
        <div class="modal-4 modal-generic"> 
        <!-- Modal content -->
          <div class="modal-content">
          <div class="modal-header-2">
              <span class="close">&times;</span>
              <h2>Content</h2>
          </div>

 

            <div class="modal-body">
              <h5>We can create outstanding content for your website.</h5>
              <h5>Featuring striking images, exciting copy and eye catching logos.
              </h5>
            </div>

       
            <div class="modal-body">
               

                <div class="container ">
                  <!-- <div class="halves-box"> -->
                      <img src="assets/images/portfolio/barone-4a.jpg"  alt="" />
                  <!-- </div> -->
                </div>

                <div class="container ">
                <div class="halves-box">
                  <img src="assets/images/portfolio/visible-portfolio-004h.jpg"  alt="" />
                </div>
                <div class="halves-box">
                  <img src="assets/images/portfolio/visible-portfolio-014h.jpg"  alt="" />
                </div>
                </div>

                <div class="container ">
                  <div class="thirds-box">
                    <img src="assets/images/portfolio/visible-portfolio-007h.jpg"  alt="" />
                  </div>
                  <div class="thirds-box">
                    <img src="assets/images/portfolio/visible-portfolio-016h.jpg"  alt="" />
                  </div>
                  <div class="thirds-box">
                    <img src="assets/images/portfolio/visible-portfolio-036h.jpg"  alt="" />
                  </div>
                </div>
                <div class="container ">
                  <div class="halves-box">
                    <img src="assets/images/portfolio/visible-portfolio-034h.jpg"  alt="" />
                  </div>
                  <div class="halves-box">
                    <img src="assets/images/portfolio/visible-portfolio-030h.jpg"  alt="" />
                </div>
                </div>


                <div class="container ">
                  <div class="thirds-box">
                    <img src="assets/images/portfolio/visible-portfolio-013h.jpg"  alt="" />
                  </div>
                  <div class="thirds-box">
                    <img src="assets/images/portfolio/jen3.jpg"  alt="" />
                  </div>
                  <div class="thirds-box">
                    <img src="assets/images/portfolio/visible-portfolio-001h.jpg"  alt="" />
                  </div>
                  </div>

                <div class="container ">
                <div class="halves-box">
                  <img src="assets/images/portfolio/visible-portfolio-025f.jpg"  alt="" />
                </div>
                <div class="halves-box">
                  <img src="assets/images/portfolio/visible-portfolio-053h.jpg"  alt="" />
                </div>
                </div>

  

                <div class="container ">
                  <div class="thirds-box">
                    <img src="assets/images/portfolio/visible-portfolio-028t.jpg"  alt="" />
                  </div>
                  <div class="thirds-box">
                    <img src="assets/images/portfolio/visible-portfolio-028h.jpg"  alt="" />
                  </div>
                  <div class="thirds-box">
                    <img src="assets/images/portfolio/visible-portfolio-030h.jpg"  alt="" />
                  </div>
                </div>

            </div>
            <div class="modal-footer">
                <h3>&nbsp;</h3>
            </div>
        </div>
    </div> 

        `
    );
  }
}

export default ModalContents;
