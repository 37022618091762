class Modal {
  constructor() {
    this.injectHTML();
    this.openModalButtons = document.querySelectorAll('.open-modal');
    this.modal = document.querySelector('.modal');
    this.closeIcon = document.querySelector('.modal__close');
    // reference document body to stop scroll on modal open
    this.docbody = document.getElementsByTagName('BODY')[0];
    this.events();
  }

  events() {
    // listen for open click
    this.openModalButtons.forEach(el =>
      el.addEventListener('click', e => this.openTheModal(e))
    );

    // listen for close click
    this.closeIcon.addEventListener('click', () => this.closeTheModal());

    // pushes any key
    document.addEventListener('keyup', e => this.keyPressHandler(e));
  }

  keyPressHandler(e) {
    if (e.keyCode == 27) {
      this.closeTheModal();
    }
  }

  openTheModal(e) {
    e.preventDefault();
    this.modal.classList.add('modal--is-visible');
    this.docbody.classList.add('body-no-scroll');
  }

  closeTheModal() {
    this.modal.classList.remove('modal--is-visible');
    this.docbody.classList.remove('body-no-scroll');
  }

  injectHTML() {
    document.body.insertAdjacentHTML(
      'beforeend',
      ` <div class="modal">
        <div class="modal__inner">
        <!--a href="mailto:visibleservicesuk@gmail.com"-->
        <!--a href="./page.html"-->
          <h2 class="section-title section-title--blue section-title--less-margin">
             <img src="assets/images/icons/mail.svg" class="section-title__icon"> Get in <strong>Touch</strong>
          </h2>
        <!--/a  -->

        <form class="vis__form" method="post" action="./mail.php">
          <label>First Name</label>
          <input required name="name" type="text" placeholder="Name" />

          <label>Email Address</label>
          <input required name="email" type="email" placeholder="Email" />

          <label>Subject</label>
          <input required name="subject" type="text" placeholder="Subject" />

          <label>Message</label>
          <textarea required name="message" rows="8" placeholder="Message"></textarea>
          
          <button type="submit" class="vis-btn">Send Message &rarr;</button>
        </form>

          <div class="wrapper wrapper--narrow">
            <p class="modal__description">For all inquiries, please email us at 
            <a href="mailto:visibleservicesuk@gmail.com">
            <strong>visibleservicesuk@gmail.com</strong></p></a>
          </div>
    
          <!--div class="social-icons">
            <a href="./page1.html" class="social-icons__icon"><img src="assets/images/icons/facebook.svg" alt="Facebook"></a>
            <a href="./page2.html" class="social-icons__icon"><img src="assets/images/icons/twitter.svg" alt="Twitter"></a>
            <a href="./page3.html" class="social-icons__icon"><img src="assets/images/icons/instagram.svg" alt="Instagram"></a>
            <a href="./page4.html" class="social-icons__icon"><img src="assets/images/icons/youtube.svg" alt="YouTube"></a>
          </div-->
        </div>
        <div class="modal__close">X</div>
      </div> `
    );
  }
}

export default Modal;
