import '../styles/styles.css';
import 'lazysizes';
import MobileMenu from './modules/MobileMenu';
import RevealOnScroll from './modules/RevealOnScroll';
import Modal from './modules/Modal';
import ModalContents from './modules/ModalsContent';

new Modal();
new MobileMenu();
new ModalContents();

let modal; // define it here and not in function block

document.querySelectorAll('.open-modal').forEach(el => {
  el.addEventListener('click', e => {
    e.preventDefault();
    // if modal has not been loaded before ie undefined
    if (typeof modal == 'undefined') {
      // weboackChunk is to rename bundled file when imported
      import(/* webpackChunkName: "modal" */ './modules/Modal')
        .then(x => {
          modal = new x.default(); // default() is required by webpack
          setTimeout(() => modal.openTheModal(), 20); // give browser time
        })
        .catch(() => console.log('There was a problem.'));
      // if modal had been loaded before
    } else {
      modal.openTheModal();
    }
  });
});

/////////////////////////////
///// Hot Module Replacement
if (module.hot) {
  module.hot.accept();
}
